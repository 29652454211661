$primaryColor: #005eb2;		//tmavo modra
$secondaryColor: #05ca00;	//zelena pre hvoer
$tertiaryColor: #ffffff;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//
$color_1: -webkit-gradient(linear,left top,right top,from(#009e4c),to(#00772f));
$color_2: linear-gradient(90deg,#009e4c 0,#00772f);

$gray: #c0c0c0;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Teko', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;


$gutter: 15px;
