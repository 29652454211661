@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

.translate-button {
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color .25s ease-out,color .25s ease-out;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: .85em 1em;
  margin: 1rem 0 1rem;
  font-size: .9rem;
  background-color: #ed6c08;
  color: #fefefe;
  text-transform: uppercase; }

.translate-button:hover {
  background-color: #c95c07;
  color: #fefefe; }

html, body {
  font-family: 'Open Sans', sans-serif; }

a {
  text-decoration: none;
  &:hover {
    text-decoration: none; } }

.nopadding {
  padding-left: 0;
  padding-right: 0; }

header {
  margin-bottom: 20px; }

.header_line {
  background: $color_1;
  background: $color_2;
  height: 30px;
  h6 {
    margin: 5px 0 0 0;
    float: right;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
      transition: 0.2s; } }
  h6:nth-child(2) {
    margin-right: 50px; }
  img {
    display: inline-block; }
  span {
    color: $tertiaryColor; } }

.search_icon {
  width: 20px;
  font-size: 14px; }

.navbar-nav li:hover .menu_level_1 {
  display: block; }

.menu_fb {
  width: 25px;
  margin: 2.5px 0 0 0;
  float: right; }

.menu_level_1:hover {
  display: block; }

.menu_level_1 {
  display: none;
  list-style: none;
  position: absolute;
  background-color: #f5f5f5;
  padding-left: 0 !important;
  color: #666;
  min-width: 12em;
  z-index: 9999;
  color: #777;
  @include transition();
  padding-left: 0.5em; }

.menu_level_1 a {
  display: block;
  color: #777;
  padding: 0 10px;
  margin: 10px 15px;
  border: 2px solid #f5f5f5;
  &:hover {
    border: 2px solid #8b76b3;
    border-radius: 999px; } }

.menu_level_1 li {
  &:hover {} }

.navbar-nav {
  margin: 0 auto;
  display: table;
  float: none; }

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  background-color: #8b76b3;
  border-radius: 999px;
  color: #ffffff; }

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #fefefe;
  background-color: #8b76b3;
  background-image: none !important;
  border-radius: 999px; }

//.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.open>a
//  background-image: none

.logo_wrap {
  margin-left: 15%;
  position: absolute; }

.logo {
  width: 110px; }

.navbar-nav>li {
  padding: 24px 0; }

.navbar-nav>li:not(:last-child) {
  margin-right: 18px; }

.navbar-default {
  box-shadow: none;
  background-color: #f5f5f5;
  border-radius: 0;
  background-image: none;
  border: none;
  margin-bottom: 0; }

.navbar-nav>li>a {
  padding-top: 5px;
  padding-bottom: 5px;
  background-image: none; }

.search_wrap {
  background-color: #00963e;
  height: 100px;
  display: none;
  position: absolute;
  width: 100%;
  z-index: 100; }

.magnifier_button {
  background-image: url('../web/magnifying-glass-black.svg');
  width: 20px;
  height: 20px;
  position: absolute;
  right: 17px;
  top: 7px; }

.search_button {
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 999px;
  padding: 9px 20px;
  margin-left: 20px;
  &:hover {
    color: black;
    background-color: #ffffff; } }

#language-drop {
  min-width: 170px;
  background-color: #00963e;
  margin-top: 20px;
  li {
    padding: 0 0 10px 20px; }
  a {
    color: #ffffff;
    &:hover {
      opacity: 0.8; } }
  hr {
    margin: 0 0 10px 0;
    width: 90%;
    margin-left: 5%;
    opacity: 0.1; }
  span {
    border-bottom: 1px solid white; } }

.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
  background-image: none;
  background-color: #00963e; }

#language-drop a:before {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  background-size: 57px 38px; }

.menu_arrow {
  position: absolute;
  width: 25px;
  top: -18px;
  right: 8px; }

#language-drop li:nth-child(1) a:before {
  background-position: 38px 0;
  top: 9px;
  left: 10px;
  background-image: url('../web/flags.png'); }

#language-drop li:nth-child(2) a:before {
  background-position: 38px 17px;
  top: 48px;
  left: 10px;
  background-image: url('../web/flags.png'); }

#language-drop li:nth-child(3) a:before {
  background-position: 0 18px;
  top: 84px;
  left: 10px;
  background-image: url('../web/flags.png'); }

#language-drop li:nth-child(4) a:before {
  background-position: 19px 35px;
  top: 120px;
  left: 10px;
  background-image: url('../web/flags.png'); }

#language-drop li:nth-child(5) a:before {
  background-position: 0 54px;
  top: 156px;
  left: 10px;
  background-image: url('../web/flags.png'); }

.inputs_wrap {
  margin: 30px auto 0 auto;
  text-align: center;
  position: relative;
  width: 100%; }

.search_button {
  margin: 30px auto 0 auto; }

.inputs_wrap input {
  width: 100%;
  padding: 8px;
  border-radius: 999px;
  border: none; }

.dropdown-menu {
  top: 30px !important;
  left: 79.5% !important; }

#lightslider {
  height: auto !important; }

.lSSlideOuter .lSPager.lSpg {
  margin: 10px 0 10px !important; }

.lSSlideOuter .lSPager.lSpg>li.active a, .lSSlideOuter .lSPager.lSpg>li:hover a {
  background-color: #00963e !important; }

//.home_slide_li
//  background-size: cover !important
//  height: 450px !important
//  background-position: center !important
//  background-repeat: no-repeat !important

.lSAction>.lSPrev {
  background-image: url('../web/back.svg') !important;
  left: 60%; }

.lSAction>.lSNext {
  background-image: url('../web/next.svg') !important;
  right: 270px; }

.slide_description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 450px;
  width: 29%; }

.slide_description h1 {
  color: #00963e;
  font-size: 38px;
  line-height: 38px;
  width: 76%; }

.slide_description span {
  color: #666;
  font-size: 30px;
  line-height: 43.5px;
  width: 76%; }

.slider_link {
  width: 76%;
  position: absolute;
  bottom: 30px; }

.slide_description a {
  background: -webkit-gradient(linear,left top,right top,from(#009e4c),to(#00772f));
  background: linear-gradient(90deg,#009e4c 0,#00772f);
  border-radius: 999px;
  padding: 20px 20px;
  color: #fff;
  font-size: 17px;
  line-height: 43px;
  font-weight: 700;
  &:hover {
    color: #ffffff;
    background: linear-gradient(90deg,#74509c 0,#74509c); } }

.product_background {
  background-image: url("../web/gray_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1125px; }

.products_heading {
  margin: 120px 0 40px;
  a {
    margin: 20px 0 0 0; } }

.body_heading_wrap {
  position: relative; }

.ev_btn {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 180px; }

.body_heading {
  font-size: 62px;
  font-weight: 700;
  line-height: 72px;
  color: #00963e;
  margin: 0 0 0; }

.body_heading_2 {
  color: #676767;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  border: 2px solid #d6d6d6;
  border-radius: 999px;
  padding: 20px 25px;
  float: right;
  margin: 10px 0 0; }

.produkt {
  height: 260px;
  background-color: white;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  img {
    width: 200px;
    margin: 0 auto; } }

.section_1 {
  margin: 50px 0;
  h2 {
    color: #00963e;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px; }
  .text-right {
    margin: 15px 0 0; }
  .col-xs-12:nth-child(1) {
    margin: 0 0 15px 0; } }

.section_1 ul li {
  list-style-type: none; }

.reliable_partner {
  border-right: 1px solid #f5f5f5; }

.section_1 ul li:before {
  content: "";
  position: absolute;
  margin-top: 6px;
  left: 30px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #00963e; }

.read_more_link {
  background: -webkit-gradient(linear,left top,right top,from(#009e4c),to(#00772f));
  background: linear-gradient(90deg,#009e4c 0,#00772f);
  border-radius: 999px;
  padding: 20px 20px;
  color: #fff;
  font-size: 17px;
  line-height: 43px;
  font-weight: 700;
  &:hover {
    color: #ffffff;
    background: linear-gradient(90deg,#74509c 0,#74509c); } }

.horse_wrap {
  position: relative;
  background-image: url("../web/home-horse_wrap-bg-201811.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 770px; }

.horse_wrap img {
  width: 1440px;
  margin: 60px auto 0 auto; }

.horse_text {
  position: absolute;
  top: 150px;
  left: 56%;
  background-color: #ffffff;
  border-radius: 50%;
  width: 570px;
  height: 570px;
  text-align: center;
  h1 {
    line-height: 20px;
    font-size: 44px;
    font-weight: 700;
    color: #000000; }
  span {
    font-weight: 400;
    line-height: 25px;
    font-size: 17px;
    color: #000000; }
  .horse_texting {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 570px;
    margin: 0 50px; } }

.article_heading {
  color: #00963e;
  font-size: 60px;
  font-weight: 400;
  line-height: 69px;
  float: left;
  margin: 0; }

.read_more_articles_link {
  float: right;
  color: #666;
  line-height: 32px;
  border: 2px solid #f5f5f5;
  background: transparent;
  border-radius: 999px;
  padding: 0 20px;
  margin: 15px 0 0 0;
  &:hover {
    color: #ffffff;
    background: linear-gradient(90deg,#74509c 0,#74509c); } }

.article_wrap {
  height: 607px;
  position: relative;
  margin: 0 0 30px 0;
  &:hover {
    opacity: 0.8;
    transition: 0.3s; } }

.article_img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px; }

.article_date {
  color: #626261;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px; }

.article_name {
  color: #666;
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; }

.article_perex {
  color: #626261;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  height: 90px;
  overflow: hidden; }

.article_read {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #74509c;
  border: 2px solid #f5f5f5;
  border-radius: 999px;
  padding: 20px 25px;
  &:hover {
    color: #00963e;
    transition: 0.3s; } }

.worldwide {
  margin: 80px 0 20px 0; }

.worldwide_contact {
  margin-top: 40px; }

.video_wrap {
  margin: 90px 0 60px 0;
  background-color: #f5f5f5;
  height: 314px;
  iframe {
    width: 100%; } }

.video_text {
  padding: 30px 78px 30px 42px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 314px;
  h1 {
    color: #00963e; } }

//footer .container-fluid
//  height: 310px
////background: -webkit-gradient(linear,left top,right top,from(#009e4c),to(#00772f))
////background: linear-gradient(90deg,#009e4c 0,#00772f)

.footer_menu_wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 455px; }

.footer_menu_wrap {
  li {
    background-color: transparent;
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    line-height: 23px;
    display: inline-block;
    margin-right: 36px;
    text-transform: uppercase;
    &:hover {
      background-color: transparent; } }
  a {
    background-color: transparent;
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    line-height: 23px;
    &:hover {
      opacity: 0.6;
      background-color: transparent; } } }

footer {
  background-image: url("../web/footer_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 455px;
  margin: 0 0 30px;
  &:before {
    content: '';
    background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245,1));
    width: 100%;
    height: 270px;
    position: absolute;
    z-index: -10; } }
.footer-menu {
  margin: 0;
  padding: 0; }

.under_footer_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  bottom: 25px;
  position: absolute;
  color: #000000; }

.article_navigation {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  a {
    color: #666;
    &:hover {
      border-bottom: 1px solid #666; } }
  span {
    color: #00963e; } }

.article_base_name {
  color: #666;
  font-weight: 400;
  font-size: 60px;
  line-height: 69px; }

.article_wraper {
  .col-sm-4 {
    margin: 0 0 30px; } }

.article_image {
  padding: 30px;
  background-color: #f5f5f5; }

.latest_news_background {
  background-color: #f5f5f5;
  .article_wrap {
    margin-bottom: 60px; } }

.article_ln_heading {
  color: #00963e;
  font-size: 60px;
  line-height: 69px;
  font-weight: 400; }

.kontakt_form {
  background-color: #f5f5f5;
  padding: 50px 20px;
  margin-top: 20px;
  .form-group {
    padding-bottom: 30px; } }

.send_button {
  float: right;
  button {
    background: -webkit-gradient(linear,left top,right top,from(#009e4c),to(#00772f));
    background: linear-gradient(90deg,#009e4c 0,#00772f);
    border-radius: 999px;
    color: #ffffff;
    margin-right: 15px;
    &:hover {
      color: #ffffff; }
    &:focus {
      color: #ffffff; } } }

.static-content {
  font-family: 'Open Sans', sans-serif !important;
  h1 {
    color: #00963e; }
  h2 {
    color: #00963e; }
  h3 {
    color: #00963e; }
  h4 {
    color: #00963e; }
  h5 {
    color: #00963e; }
  h6 {
    color: #00963e; } }

.content-web {
  font-family: 'Open Sans', sans-serif !important; }

.menu_level_1 {
  top: 78px !important;
  &:before {
    content: '';
    background-image: url('../web/caret-arrow-up-g.png');
    background-repeat: no-repeat;
    background-position: 30px 8px;
    display: block;
    background-size: contain;
    height: 30px;
    background-color: #ffffff; } }

.horse_texting_alter {
  display: none; }

// PRODUKTY
.produkts_list_wrap {
  background-color: #f5f5f5;
  margin: -20px 0 0 0;
  padding: 0 0 40px 0;
  h1 {
    color: #00963e; } }

.produkt_list_wrap {
  border: 1px solid #fefefe;
  height: 210px;
  position: relative;
  background-color: #ffffff;
  margin: 25px 0 10px;
  transition: 0.2s;
  padding: 0 0 0 15px;
  .produkt_icon {
    position: absolute;
    top: -17px;
    left: -17px;
    width: 50px; }
  h2 {
    font-size: 17px;
    line-height: 21px;
    font-weight: 700;
    color: #7861a0;
    text-transform: uppercase;
    margin: 20px 0 0;
    padding: 0 10px; }
  h3 {
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
    color: #00963e;
    text-transform: uppercase;
    margin: 15px 0 0;
    padding: 0 10px; }
  p {
    margin: 15px 0 0;
    font-size: 13px;
    line-height: 15px;
    font-weight: light;
    color: #000000;
    padding: 0 10px; }
  .block_1 {
    background-repeat: no-repeat;
    background-size: cover;
    width: 40%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 170px;
    margin: 20px 0; }
  .block_2 {
    width: 60%;
    float: left; }
  &:hover {
    transform: scale(1.03);
    transition: 0.2s; } }

.produkt_image {
  width: 80%; }

.produkt_info_block_wrap {
  position: relative;
  h1 {
    color: #00963e;
    text-transform: uppercase;
    font-size: 36px;
    margin: 0;
    width: 70%; }
  h2 {
    color: #00963e;
    text-transform: uppercase;
    font-size: 30px;
    width: 70%; }
  h3 {
    color: #00963e;
    text-transform: uppercase;
    font-size: 24px; }
  h4, h5, h6 {
    color: #00963e; }
  .short_produkt_content {
    font-size: 14px;
    //font-weight: 600
    line-height: 21px;
    color: #0a0a0a; }
  .ev_btn_detail {
    position: absolute;
    right: 20px;
    top: 30px;
    width: 155px; } }

.produkt_info_block_wrap_2 {
  margin: 15px 0;
  h1, h2, h3, h4, h5, h6 {
    color: #00963e; } }

.another_produkt_heading {
  text-transform: uppercase;
  color: #00963e;
  font-size: 24px; }

.another_produkt_main_wrap {
  margin: 0 0 35px; }

.another_produkt_wrap {
  border: 1px solid #999;
  text-align: center;
  transition: 0.2s;
  h3 {
    color: #999;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px; }
  img {
    margin: 10px auto; }
  h4 {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 170px; }
  &:hover {
    //transform: scale(1.03)
    transition: 0.2s;
    h3 {
      color: #00963e; } } }


// VYHLADAVANIE
.photo_searching {
  height: 210px;
  background-size: contain;
  background-repeat: no-repeat; }

// COOKIE
.cookieinfo {
  background: rgb(0, 133, 58) !important;
  color: rgb(255, 255, 255) !important;
  line-height: 21px !important;
  padding: 22px 18px !important;
  .cookieinfo-close {
    font-weight: 700 !important;
    font-size: 11px !important;
    color: rgb(255, 255, 255) !important;
    background: rgb(117, 95, 155) !important;
    border-radius: 999px !important;
    margin: 4px 0 0 5px !important; }
  span {
    padding: 11px 0 5px 0 !important; } }


// STATIC CONTENT
.produkte_de_tab th {
  background-color: #f4f4f4;
  text-align: center;
  padding: 14px;
  padding: 14px !important;
  border-left: 3px solid white;
  border-right: 4px solid white;
  border-color: white;
  color: #6f6f6f;
  border-bottom: none !important; }

.produkte_de_tab td {
  border: none !important; }

.packung_de_tab {
  width: 100%;
  padding: 14px;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 15px; }

.packung_de_tab tr td:nth-of-type(odd) {
  background-color: #f4f4f4;
  padding: 5px 10px 5px 0px;
  border-right: 1px solid #999;
  border-right: 1px solid #999;
  border-bottom: none;
  border-top: none;
  border-left: none; }

.packung_de_tab tr td:nth-of-type(even) {
  padding: 5px 0px 5px 10px;
  border: none; }

.kaufen_wrap {
  .company {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    flex-direction: row;
    height: 160px;
    margin: 0 0 60px;
    .block_1 {
      width: 40%;
      padding: 10px;
      img {
        width: 150px; } }
    .block_2 {
      padding: 15px;
      width: 60%;
      h2 {
        color: green;
        font-size: 14px; }
      a {
        color: gray;
        text-decoration: none;
        margin: 0;
        &:hover {
          color: green; } } } } }

.eshop_btn_wrap {
  position: fixed;
  right: 0;
  bottom: 30px;
  padding: 15px;
  z-index: 100;
  background-color: #fefefe;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 200px;
  box-shadow: 0px 0px 35px rgba(0,0,0,0.15);
  text-align: center;
  border: 1px solid #e6e6e6;
  border-right: none;
  .eshop_btn {
    a {
      text-decoration: none;
      background: linear-gradient(90deg,#d40505 0,#a90505);
      padding: 10px 50px 10px 20px;
      position: relative;
      &:hover, &:focus {
        background: #d40505;
        color: #ffffff; }
      .cart {
        width: 20px;
        display: inline-block;
        position: absolute;
        right: 25px;
        top: 50%;
        margin: -10px 0 0; } } } }

@media screen and (max-width: 1800px) {
  .lSAction>.lSNext {
    right: 12%; } }

@media screen and (max-width: 1600px) {
  .lSAction>.lSNext {
    right: 10%; } }

@media screen and (max-width: 1500px) {
  .lSAction>.lSNext {
    right: 100px; }

  .slide_description span {
    width: 100%;
    margin-left: 90px; } }

@media screen and (max-width: 1199px) {
  .logo_wrap {
    margin-left: 50px; }

  .slide_description {
    height: 420px;
    span {
      width: 300px;
      margin-left: 130px; }
    h1 {
      width: 300px;
      margin: 0 0 10px 130px; } }

  .slider_link {
    margin: 0 0 100px 20px;
    bottom: -70px; }

  .lSAction>.lSNext {
    right: 10px; }

  .produkt {
    height: 260px; }

  .horse_text {
    top: 100px;
    left: 48%; }

  .dropdown-menu {
    left: 67.5% !important; }

  .horse_text h1 {
    margin-top: 0; }

  .article_name {
    height: 100px; }

  .article_perex {
    height: 110px; }

  .produkt_list_wrap {
    h3 {
      margin: 5px 0 0 0; }
    p {
      margin: 5px 0 0 0; } }

  .another_produkt_wrap {
    h3 {
      height: 90px; } }

  .ev_btn_detail {
    width: 115px !important; } }

@media screen and (max-width: 991px) {
  .slide_description {
    height: 280px;
    justify-content: flex-start; }

  .slide_description h1 {
    font-size: 30px;
    line-height: 36px;
    width: 270px;
    margin-left: 90px; }

  .slide_description span {
    font-size: 20px;
    line-height: 33.5px;
    width: 270px;
    margin-left: 90px; }

  .lSAction>.lSPrev {
    left: 55%; }

  .lSAction>.lSNext {
    right: 0; }

  .dropdown-menu {
    left: 78% !important; }

  .logo_wrap {
    margin-left: 0; }

  .produkt {
    height: 200px; }

  .horse_text {
    top: 20px;
    left: 50%;
    margin-left: -210px; }

  .horse_text h1 {
    margin: 0;
    line-height: 49px;
    font-size: 40px; }

  .horse_text span {
    line-height: 25px;
    font-size: 17px; }

  .kontakt_form {
    margin-top: 50px; }

  .article_wrap {
    height: 627px; }

  .article_name {
    font-size: 20px;
    line-height: 24px;
    height: 70px; }

  .article_perex {
    height: 150px; }

  .body_heading {
    font-size: 42px;
    line-height: 52px; }

  .product_background {
    background-image: none;
    height: auto;
    background-color: #f5f5f5; }

  .ev_btn_detail {
    right: 0 !important; }

  .ev_btn {
    width: 180px !important;
    top: 5px !important; } }

@media screen and (max-width: 850px) {
  .logo {
    width: 80px; }

  .logo_wrap {
    margin-top: 5px; } }

@media screen and (max-width: 767px) {
  .logo_wrap {
    margin: 0;
    left: 50%;
    margin-left: -42px;
    top: -10px; }

  .navbar-toggle {
    float: left;
    margin-left: 20px; }

  .navbar-nav {
    display: block !important; }

  .navbar-nav>li {
    margin: 0 !important; }

  .navbar-nav {
    height: 400px;
    margin: 0 auto;
    display: table;
    float: none;
    li:nth-child(6) {
      &:after {
        content: '';
        background-image: url('../web/caret-down.png');
        background-repeat: no-repeat;
        background-position: 50% 0;
        display: block;
        background-size: contain;
        height: 30px;
        background-color: #f5f5f5; } } }

  .menu_level_1 {
    left: 50%;
    margin-left: -166px;
    display: block;
    &:before {
      display: none; } }

  .search_wrap {
    height: auto;
    padding-bottom: 15px; }

  .search_button_wrap {
    width: 200px;
    margin: 0 auto;
    text-align: center; }

  #drop_country_wrap {
    float: right; }

  #language-drop {
    left: -96px !important; }

  .lSAction>.lSPrev {
    display: none; }

  .lSAction>.lSNext {
    display: none; }

  .slide_description {
    width: 100%;
    justify-content: flex-start;
    height: 250px;
    h1 {
      width: 100%;
      margin-left: 0;
      margin-top: 0; }
    span {
      width: 100%;
      margin-left: 0; } }

  .slider_link {
    width: 100%; }

  .produkt img {
    width: 150px; }

  .horse_texting {
    display: none; }

  .horse_texting_alter {
    display: block; }

  .article_wrap {
    height: 550px; }

  .video_wrap {
    height: auto;
    .video_text {
      height: auto; } }

  .footer-menu {
    .menu_level_1 {
      display: none; }
    li {
      display: block;
      margin: 10px 0;
      text-align: center; } }

  .navbar-nav>li {
    padding: 5px 0; }

  .slider_link {
    margin: 0 0 30px 20px;
    bottom: 0; }

  .home_slide_li {
    height: auto;
    overflow: hidden; }

  .body_heading {
    margin: 0 0 30px;
    float: none !important; }

  .body_heading_2 {
    float: none !important; }

  .horse_text {
    width: auto;
    height: auto;
    top: 50px; }

  .ev_btn_detail {
    position: static !important;
    margin: 20px 0;
    width: 155px !important; }

  .produkt_info_block_wrap {
    h1 {
      width: 100%; }
    h2 {
      width: 100%; } }

  .eshop_btn_wrap {
    width: 160px;
    padding: 10px;
    .eshop_btn {
      a {
        font-size: 12px;
        padding: 10px 45px 10px 20px;
        img {
          right: 20px !important; } } } } }

@media screen and (max-width: 575px) {
  .ev_btn {
    position: static !important;
    margin: 0 0 20px !important; } }

@media screen and (max-width: 400px) {
  .horse_text {
    left: 55%;
    span {
      line-height: 22px;
      font-size: 16px; } }

  .article_wrap {
    height: 570px; }

  .produkt_list_wrap {
    h3 {
      margin: 5px 0 0; }
    p {
      margin: 5px 0 0; } }

  .kaufen_wrap {
    .company {
      .block_2 {
        h2 {
          font-size: 12px; } } } } }
