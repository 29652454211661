@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

//h2
//	text-transform: uppercase
//	text-align: center
//	margin-top: 3 * $gutter
//	margin-bottom: $gutter


.references {
	padding-top: 30px;
	background-color: $tertiaryColor;
	color: #000;
	.heading-contact-form {
		margin-top: 0;
		font-size: 32px;
		text-align: left;
		color: #005eb2; }
	h2 {
		color: #000;
		span {
			display: inline-block;
			&:before {
				content: '';
				display: block;
				width: 80%;
				height: 6px;
				margin: 0px auto;
				background-color: $primaryColor; } } } }

